/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://rgsvmia3njbobpsuofkasidy6m.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-2a7lpqeotfapjhrl3mye3b4tp4",
    "aws_cognito_identity_pool_id": "us-west-2:31a85302-a7a6-45ee-b5ac-7a6bcab11e35",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_DNChhd2h2",
    "aws_user_pools_web_client_id": "53tqc5mqpk0dt2e30dud6dmggp",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "thinkmail-static83015-staging",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
